module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"http://wordpress-604950-1959020.cloudwaysapps.com/graphql","debug":{"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"writeQueriesToDisk":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"timeBuildSteps":true,"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"schema":{"perPage":20,"timeout":1800000,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"excludeFieldNames":["ActionMonitor","comments","blocksJSON","previewBlocks","previewBlocksJSON","attributesJSON"],"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":1,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Comment":{"limit":0},"Post":{"where":"language: EN, tagSlugIn: null","beforeChangeNode":"./src/utilities/beforeChangeNodePost","excludeFieldNames":["previewBlocks","previewBlocksJSON","blocksJSON"]},"Category":{"where":"language: EN","beforeChangeNode":"./src/utilities/beforeChangeNodeCategory"},"Page":{"where":"language: EN, tagSlugIn: null","beforeChangeNode":"./src/utilities/beforeChangeNodePage"},"Block":{"excludeFieldNames":["attributesJSON"]},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"hardCacheMediaFiles":false,"nodeUpdateInterval":5000,"hardCacheData":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ridestore Magazine","short_name":"Ridestore Magazine","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"src/assets/images/ridestore-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"192e57fd9e4d4c86f26f6ad3c2055f79"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fresnel/gatsby-browser.js'),
      options: {"plugins":[],"breakpoints":{"mobile":0,"tablet":768,"desktop":1366}},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"ridestoremagazine.imgix.net","secureURLToken":"CEcMt2dz886KnaeT","sourceType":"webProxy","defaultImgixParams":{"auto":"format,compress"},"fields":[{"nodeType":"WpMediaItem","fieldName":"imgixImage"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
